@keyframes plyr-progress{
  to{
      background-position:25px 0
  }
}
@keyframes plyr-popup{
  0%{
      opacity:.5;
      transform:translateY(10px)
  }
  to{
      opacity:1;
      transform:translateY(0)
  }
}
@keyframes plyr-fade-in{
  from{
      opacity:0
  }
  to{
      opacity:1
  }
}
.plyr{
  -moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased;
  direction:ltr;
  font-family:Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  font-variant-numeric:tabular-nums;
  font-weight:500;
  line-height:1.7;
  max-width:100%;
  min-width:200px;
  position:relative;
  text-shadow:none;
  transition:box-shadow .3s ease
}
.plyr audio,.plyr video{
  border-radius:inherit;
  height:auto;
  vertical-align:middle;
  width:100%
}
.plyr button{
  font:inherit;
  line-height:inherit;
  width:auto
}
.plyr:focus{
  outline:0
}
.plyr--full-ui{
  box-sizing:border-box
}
.plyr--full-ui *,.plyr--full-ui ::after,.plyr--full-ui ::before{
  box-sizing:inherit
}
.plyr--full-ui a,.plyr--full-ui button,.plyr--full-ui input,.plyr--full-ui label{
  touch-action:manipulation
}
.plyr__badge{
  background:#4f5b5f;
  border-radius:2px;
  color:#fff;
  font-size:9px;
  line-height:1;
  padding:3px 4px
}
.plyr--full-ui ::-webkit-media-text-track-container{
  display:none
}
.plyr__captions{
  animation:plyr-fade-in .3s ease;
  bottom:0;
  color:#fff;
  display:none;
  font-size:14px;
  left:0;
  padding:10px;
  position:absolute;
  text-align:center;
  transform:translateY(-40px);
  transition:transform .4s ease-in-out;
  width:100%
}
.plyr__captions span{
  background:rgba(0,0,0,.8);
  border-radius:2px;
  -webkit-box-decoration-break:clone;
  box-decoration-break:clone;
  line-height:185%;
  padding:.2em .5em;
  white-space:pre-wrap
}
.plyr__captions span div{
  display:inline
}
.plyr__captions span:empty{
  display:none
}
@media (min-width:480px){
  .plyr__captions{
      font-size:16px;
      padding:20px
  }
}
@media (min-width:768px){
  .plyr__captions{
      font-size:18px
  }
}
.plyr--captions-active .plyr__captions{
  display:block
}
.plyr--hide-controls .plyr__captions{
  transform:translateY(-15px)
}
.plyr__control{
  background:0 0;
  border:0;
  border-radius:3px;
  color:inherit;
  cursor:pointer;
  flex-shrink:0;
  overflow:visible;
  padding:7px;
  position:relative;
  transition:all .3s ease
}
.plyr__control svg{
  display:block;
  fill:currentColor;
  height:18px;
  pointer-events:none;
  width:18px
}
.plyr__control:focus{
  outline:0
}
.plyr__control.plyr__tab-focus{
  box-shadow:0 0 0 3px rgba(255, 205, 28,.35);
  outline:0
}
.plyr__control[aria-pressed=false] .icon--pressed,.plyr__control[aria-pressed=false] .label--pressed,.plyr__control[aria-pressed=true] .icon--not-pressed,.plyr__control[aria-pressed=true] .label--not-pressed{
  display:none
}
.plyr--audio .plyr__control.plyr__tab-focus,.plyr--audio .plyr__control:hover,.plyr--audio .plyr__control[aria-expanded=true]{
  background:#FFCD1C;
  color:#fff
}
.plyr__control--overlaid{
  background:rgba(255, 205, 28,.8);
  border:0;
  border-radius:100%;
  box-shadow:0 1px 1px rgba(0,0,0,.15);
  color:#fff;
  display:none;
  left:50%;
  padding:15px;
  position:absolute;
  top:50%;
  transform:translate(-50%,-50%);
  z-index:2
}
.plyr__control--overlaid svg{
  height:20px;
  left:2px;
  position:relative;
  width:20px
}
.plyr__control--overlaid:focus,.plyr__control--overlaid:hover{
  background:#FFCD1C
}
.plyr--playing .plyr__control--overlaid{
  opacity:0;
  visibility:hidden
}
.plyr--full-ui.plyr--video .plyr__control--overlaid{
  display:block
}
.plyr--full-ui ::-webkit-media-controls{
  display:none
}
.plyr__controls{
  align-items:center;
  display:flex;
  text-align:center
}
.plyr__controls .plyr__menu,.plyr__controls .plyr__progress,.plyr__controls .plyr__time,.plyr__controls>.plyr__control{
  margin-left:5px
}
.plyr__controls .plyr__menu:first-child,.plyr__controls .plyr__menu:first-child+[data-plyr=pause],.plyr__controls .plyr__progress:first-child,.plyr__controls .plyr__progress:first-child+[data-plyr=pause],.plyr__controls .plyr__time:first-child,.plyr__controls .plyr__time:first-child+[data-plyr=pause],.plyr__controls>.plyr__control:first-child,.plyr__controls>.plyr__control:first-child+[data-plyr=pause]{
  margin-left:0
}
.plyr__controls .plyr__volume{
  margin-left:5px
}
@media (min-width:480px){
  .plyr__controls .plyr__menu,.plyr__controls .plyr__progress,.plyr__controls .plyr__time,.plyr__controls>.plyr__control{
      margin-left:10px
  }
  .plyr__controls .plyr__menu+.plyr__control,.plyr__controls>.plyr__control+.plyr__control,.plyr__controls>.plyr__control+.plyr__menu{
      margin-left:5px
  }
}
.plyr--video .plyr__controls{
  background:linear-gradient(transparent,rgba(0,0,0,.7));
  border-bottom-left-radius:inherit;
  border-bottom-right-radius:inherit;
  bottom:0;
  color:#fff;
  left:0;
  padding:35px 10px 10px;
  position:absolute;
  right:0;
  transition:opacity .4s ease-in-out,transform .4s ease-in-out;
  z-index:2
}
.plyr--video .plyr__controls .plyr__control svg{
  -webkit-filter:drop-shadow(0 1px 1px rgba(0, 0, 0, .15));
  filter:drop-shadow(0 1px 1px rgba(0, 0, 0, .15))
}
.plyr--video .plyr__controls .plyr__control.plyr__tab-focus,.plyr--video .plyr__controls .plyr__control:hover,.plyr--video .plyr__controls .plyr__control[aria-expanded=true]{
  background:#FFCD1C;
  color:#fff
}
.plyr--audio .plyr__controls{
  background:#fff;
  border-radius:inherit;
  color:#4f5b5f;
  padding:10px
}
.plyr--video.plyr--hide-controls .plyr__controls{
  opacity:0;
  pointer-events:none;
  transform:translateY(100%)
}
.plyr [data-plyr=airplay],.plyr [data-plyr=captions],.plyr [data-plyr=fullscreen],.plyr [data-plyr=pip]{
  display:none
}
.plyr--airplay-supported [data-plyr=airplay],.plyr--captions-enabled [data-plyr=captions],.plyr--fullscreen-enabled [data-plyr=fullscreen],.plyr--pip-supported [data-plyr=pip]{
  display:inline-block
}
.plyr__video-embed{
  height:0;
  padding-bottom:56.25%;
  position:relative
}
.plyr__video-embed iframe{
  border:0;
  height:100%;
  left:0;
  position:absolute;
  top:0;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  width:100%
}
.plyr--full-ui .plyr__video-embed iframe{
  pointer-events:none
}
.plyr--full-ui .plyr__video-embed>div{
  padding-bottom:240%;
  position:relative;
  transform:translateY(-38.28125%)
}
.plyr__menu{
  display:flex;
  position:relative
}
.plyr__menu .plyr__control svg{
  transition:transform .3s ease
}
.plyr__menu .plyr__control[aria-expanded=true] svg{
  transform:rotate(90deg)
}
.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip{
  display:none
}
.plyr__menu__container{
  animation:plyr-popup .2s ease;
  background:rgba(255,255,255,.9);
  border-radius:4px;
  bottom:100%;
  box-shadow:0 1px 2px rgba(0,0,0,.15);
  color:#4f5b5f;
  font-size:16px;
  margin-bottom:10px;
  position:absolute;
  right:-3px;
  text-align:left;
  white-space:nowrap;
  z-index:3
}
.plyr__menu__container>div{
  overflow:hidden;
  transition:height .35s cubic-bezier(.4,0,.2,1),width .35s cubic-bezier(.4,0,.2,1)
}
.plyr__menu__container::after{
  border:4px solid transparent;
  border-top-color:rgba(255,255,255,.9);
  content:'';
  height:0;
  position:absolute;
  right:15px;
  top:100%;
  width:0
}
.plyr__menu__container ul{
  list-style:none;
  margin:0;
  overflow:hidden;
  padding:7px
}
.plyr__menu__container ul li{
  margin-top:2px
}
.plyr__menu__container ul li:first-child{
  margin-top:0
}
.plyr__menu__container .plyr__control{
  align-items:center;
  color:#4f5b5f;
  display:flex;
  font-size:14px;
  padding:4px 14px;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  width:100%
}
.plyr__menu__container .plyr__control::after{
  border:4px solid transparent;
  content:'';
  position:absolute;
  top:50%;
  transform:translateY(-50%)
}
.plyr__menu__container .plyr__control--forward{
  padding-right:28px
}
.plyr__menu__container .plyr__control--forward::after{
  border-left-color:rgba(79,91,95,.8);
  right:5px
}
.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after,.plyr__menu__container .plyr__control--forward:hover::after{
  border-left-color:currentColor
}
.plyr__menu__container .plyr__control--back{
  font-weight:500;
  margin:7px;
  margin-bottom:3px;
  padding-left:28px;
  position:relative;
  width:calc(100% - 14px)
}
.plyr__menu__container .plyr__control--back::after{
  border-right-color:rgba(79,91,95,.8);
  left:7px
}
.plyr__menu__container .plyr__control--back::before{
  background:#b7c5cd;
  box-shadow:0 1px 0 #fff;
  content:'';
  height:1px;
  left:0;
  margin-top:4px;
  overflow:hidden;
  position:absolute;
  right:0;
  top:100%
}
.plyr__menu__container .plyr__control--back.plyr__tab-focus::after,.plyr__menu__container .plyr__control--back:hover::after{
  border-right-color:currentColor
}
.plyr__menu__container label.plyr__control{
  padding-left:7px
}
.plyr__menu__container label.plyr__control input[type=radio]+span{
  background:rgba(0,0,0,.1);
  border-radius:100%;
  display:block;
  flex-shrink:0;
  height:16px;
  margin-right:10px;
  position:relative;
  transition:all .3s ease;
  width:16px
}
.plyr__menu__container label.plyr__control input[type=radio]+span::after{
  background:#fff;
  border-radius:100%;
  content:'';
  height:6px;
  left:5px;
  opacity:0;
  position:absolute;
  top:5px;
  transform:scale(0);
  transition:transform .3s ease,opacity .3s ease;
  width:6px
}
.plyr__menu__container label.plyr__control input[type=radio]:checked+span{
  background:#FFCD1C
}
.plyr__menu__container label.plyr__control input[type=radio]:checked+span::after{
  opacity:1;
  transform:scale(1)
}
.plyr__menu__container label.plyr__control input[type=radio]:focus+span{
  box-shadow:0 0 0 3px rgba(255, 205, 28,.35);
  outline:0
}
.plyr__menu__container label.plyr__control.plyr__tab-focus input[type=radio]+span,.plyr__menu__container label.plyr__control:hover input[type=radio]+span{
  background:rgba(0,0,0,.1)
}
.plyr__menu__container .plyr__menu__value{
  align-items:center;
  display:flex;
  margin-left:auto;
  margin-right:-7px;
  overflow:hidden;
  padding-left:25px;
  pointer-events:none
}
.plyr__progress{
  display:flex;
  flex:1;
  position:relative
}
.plyr__progress input[type=range]{
  position:relative;
  z-index:2
}
.plyr__progress .plyr__tooltip{
  font-size:14px;
  left:0
}
.plyr__progress--buffer{
  -webkit-appearance:none;
  background:0 0;
  border:0;
  border-radius:100px;
  height:6px;
  left:0;
  margin:-3px 0 0;
  padding:0;
  position:absolute;
  top:50%;
  width:100%
}
.plyr__progress--buffer::-webkit-progress-bar{
  background:0 0;
  transition:width .2s ease
}
.plyr__progress--buffer::-webkit-progress-value{
  background:currentColor;
  border-radius:100px;
  min-width:6px
}
.plyr__progress--buffer::-moz-progress-bar{
  background:currentColor;
  border-radius:100px;
  min-width:6px;
  transition:width .2s ease
}
.plyr__progress--buffer::-ms-fill{
  border-radius:100px;
  transition:width .2s ease
}
.plyr--video .plyr__progress--buffer{
  box-shadow:0 1px 1px rgba(0,0,0,.15);
  color:rgba(255,255,255,.25)
}
.plyr--audio .plyr__progress--buffer{
  color:rgba(183,197,205,.66)
}
.plyr--loading .plyr__progress--buffer{
  animation:plyr-progress 1s linear infinite;
  background-image:linear-gradient(-45deg,rgba(47,52,61,.6) 25%,transparent 25%,transparent 50%,rgba(47,52,61,.6) 50%,rgba(47,52,61,.6) 75%,transparent 75%,transparent);
  background-repeat:repeat-x;
  background-size:25px 25px;
  color:transparent
}
.plyr--video.plyr--loading .plyr__progress--buffer{
  background-color:rgba(255,255,255,.25)
}
.plyr--audio.plyr--loading .plyr__progress--buffer{
  background-color:rgba(183,197,205,.66)
}
.plyr__poster{
  background-color:#000;
  background-position:50% 50%;
  background-repeat:no-repeat;
  background-size:100% 100%;
  height:100%;
  left:0;
  opacity:0;
  position:absolute;
  top:0;
  transition:opacity .3s ease;
  width:100%;
  z-index:1
}
.plyr--stopped .plyr__poster{
  opacity:1
}
.plyr--full-ui input[type=range]{
  -webkit-appearance:none;
  background:0 0;
  border:0;
  border-radius:28px;
  color:#FFCD1C;
  display:block;
  height:20px;
  margin:0;
  padding:0;
  transition:box-shadow .3s ease;
  width:100%
}
.plyr--full-ui input[type=range]::-webkit-slider-runnable-track{
  background:0 0;
  border:0;
  border-radius:3px;
  height:6px;
  transition:all .3s ease;
  -webkit-user-select:none;
  user-select:none;
  background-image:linear-gradient(to right,currentColor var(--value,0),transparent var(--value,0))
}
.plyr--full-ui input[type=range]::-webkit-slider-thumb{
  background:#fff;
  border:0;
  border-radius:100%;
  box-shadow:0 1px 1px rgba(0,0,0,.15),0 0 0 1px rgba(47,52,61,.2);
  box-sizing:border-box;
  height:14px;
  position:relative;
  transition:all .2s ease;
  width:14px;
  -webkit-appearance:none;
  margin-top:-4px
}
.plyr--full-ui input[type=range]::-moz-range-track{
  background:0 0;
  border:0;
  border-radius:3px;
  height:6px;
  transition:all .3s ease;
  -moz-user-select:none;
  user-select:none
}
.plyr--full-ui input[type=range]::-moz-range-thumb{
  background:#fff;
  border:0;
  border-radius:100%;
  box-shadow:0 1px 1px rgba(0,0,0,.15),0 0 0 1px rgba(47,52,61,.2);
  box-sizing:border-box;
  height:14px;
  position:relative;
  transition:all .2s ease;
  width:14px
}
.plyr--full-ui input[type=range]::-moz-range-progress{
  background:currentColor;
  border-radius:3px;
  height:6px
}
.plyr--full-ui input[type=range]::-ms-track{
  background:0 0;
  border:0;
  border-radius:3px;
  height:6px;
  transition:all .3s ease;
  -ms-user-select:none;
  user-select:none;
  color:transparent
}
.plyr--full-ui input[type=range]::-ms-fill-upper{
  background:0 0;
  border:0;
  border-radius:3px;
  height:6px;
  transition:all .3s ease;
  -ms-user-select:none;
  user-select:none
}
.plyr--full-ui input[type=range]::-ms-fill-lower{
  background:0 0;
  border:0;
  border-radius:3px;
  height:6px;
  transition:all .3s ease;
  -ms-user-select:none;
  user-select:none;
  background:currentColor
}
.plyr--full-ui input[type=range]::-ms-thumb{
  background:#fff;
  border:0;
  border-radius:100%;
  box-shadow:0 1px 1px rgba(0,0,0,.15),0 0 0 1px rgba(47,52,61,.2);
  box-sizing:border-box;
  height:14px;
  position:relative;
  transition:all .2s ease;
  width:14px;
  margin-top:0
}
.plyr--full-ui input[type=range]::-ms-tooltip{
  display:none
}
.plyr--full-ui input[type=range]:focus{
  outline:0
}
.plyr--full-ui input[type=range]::-moz-focus-outer{
  border:0
}
.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track{
  box-shadow:0 0 0 3px rgba(255, 205, 28,.35);
  outline:0
}
.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track{
  box-shadow:0 0 0 3px rgba(255, 205, 28,.35);
  outline:0
}
.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track{
  box-shadow:0 0 0 3px rgba(255, 205, 28,.35);
  outline:0
}
.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track{
  background-color:rgba(255,255,255,.25)
}
.plyr--full-ui.plyr--video input[type=range]::-moz-range-track{
  background-color:rgba(255,255,255,.25)
}
.plyr--full-ui.plyr--video input[type=range]::-ms-track{
  background-color:rgba(255,255,255,.25)
}
.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb{
  box-shadow:0 1px 1px rgba(0,0,0,.15),0 0 0 1px rgba(47,52,61,.2),0 0 0 3px rgba(255,255,255,.5)
}
.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb{
  box-shadow:0 1px 1px rgba(0,0,0,.15),0 0 0 1px rgba(47,52,61,.2),0 0 0 3px rgba(255,255,255,.5)
}
.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb{
  box-shadow:0 1px 1px rgba(0,0,0,.15),0 0 0 1px rgba(47,52,61,.2),0 0 0 3px rgba(255,255,255,.5)
}
.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track{
  background-color:rgba(183,197,205,.66)
}
.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track{
  background-color:rgba(183,197,205,.66)
}
.plyr--full-ui.plyr--audio input[type=range]::-ms-track{
  background-color:rgba(183,197,205,.66)
}
.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb{
  box-shadow:0 1px 1px rgba(0,0,0,.15),0 0 0 1px rgba(47,52,61,.2),0 0 0 3px rgba(0,0,0,.1)
}
.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb{
  box-shadow:0 1px 1px rgba(0,0,0,.15),0 0 0 1px rgba(47,52,61,.2),0 0 0 3px rgba(0,0,0,.1)
}
.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb{
  box-shadow:0 1px 1px rgba(0,0,0,.15),0 0 0 1px rgba(47,52,61,.2),0 0 0 3px rgba(0,0,0,.1)
}
.plyr__time{
  font-size:14px
}
.plyr__time+.plyr__time::before{
  content:'\2044';
  margin-right:10px
}
@media (max-width:767px){
  .plyr__time+.plyr__time{
      display:none
  }
}
.plyr--video .plyr__time{
  text-shadow:0 1px 1px rgba(0,0,0,.15)
}
.plyr__tooltip{
  background:rgba(255,255,255,.9);
  border-radius:3px;
  bottom:100%;
  box-shadow:0 1px 2px rgba(0,0,0,.15);
  color:#4f5b5f;
  font-size:14px;
  font-weight:500;
  line-height:1.3;
  margin-bottom:10px;
  opacity:0;
  padding:5px 7.5px;
  pointer-events:none;
  position:absolute;
  transform:translate(-50%,10px) scale(.8);
  transform-origin:50% 100%;
  transition:transform .2s .1s ease,opacity .2s .1s ease;
  z-index:2
}
.plyr__tooltip::before{
  border-left:4px solid transparent;
  border-right:4px solid transparent;
  border-top:4px solid rgba(255,255,255,.9);
  bottom:-4px;
  content:'';
  height:0;
  left:50%;
  position:absolute;
  transform:translateX(-50%);
  width:0;
  z-index:2
}
.plyr .plyr__control.plyr__tab-focus .plyr__tooltip,.plyr .plyr__control:hover .plyr__tooltip,.plyr__tooltip--visible{
  opacity:1;
  transform:translate(-50%,0) scale(1)
}
.plyr .plyr__control:hover .plyr__tooltip{
  z-index:3
}
.plyr__controls>.plyr__control:first-child .plyr__tooltip,.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip{
  left:0;
  transform:translate(0,10px) scale(.8);
  transform-origin:0 100%
}
.plyr__controls>.plyr__control:first-child .plyr__tooltip::before,.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip::before{
  left:16px
}
.plyr__controls>.plyr__control:last-child .plyr__tooltip{
  right:0;
  transform:translate(0,10px) scale(.8);
  transform-origin:100% 100%
}
.plyr__controls>.plyr__control:last-child .plyr__tooltip::before{
  left:auto;
  right:16px;
  transform:translateX(50%)
}
.plyr__controls>.plyr__control:first-child .plyr__tooltip--visible,.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip--visible,.plyr__controls>.plyr__control:first-child+.plyr__control.plyr__tab-focus .plyr__tooltip,.plyr__controls>.plyr__control:first-child+.plyr__control:hover .plyr__tooltip,.plyr__controls>.plyr__control:first-child.plyr__tab-focus .plyr__tooltip,.plyr__controls>.plyr__control:first-child:hover .plyr__tooltip,.plyr__controls>.plyr__control:last-child .plyr__tooltip--visible,.plyr__controls>.plyr__control:last-child.plyr__tab-focus .plyr__tooltip,.plyr__controls>.plyr__control:last-child:hover .plyr__tooltip{
  transform:translate(0,0) scale(1)
}
.plyr--video{
  overflow:hidden
}
.plyr--video.plyr--menu-open{
  overflow:visible
}
.plyr__video-wrapper{
  background:#000;
  border-radius:inherit;
  overflow:hidden;
  position:relative;
  z-index:0
}
.plyr__volume{
  flex:1;
  position:relative
}
.plyr__volume input[type=range]{
  position:relative;
  z-index:2
}
@media (min-width:480px){
  .plyr__volume{
      max-width:50px
  }
}
@media (min-width:768px){
  .plyr__volume{
      max-width:80px
  }
}
.plyr--is-ios .plyr__volume{
  display:none!important
}
.plyr--is-ios.plyr--vimeo [data-plyr=mute]{
  display:none!important
}
.plyr--has-error{
  pointer-events:none
}
.plyr--has-error::after{
  align-items:center;
  background:#000;
  color:#fff;
  content:attr(data-plyr-error);
  display:flex;
  font-size:16px;
  height:100%;
  justify-content:center;
  left:0;
  position:absolute;
  text-align:center;
  text-shadow:0 1px 1px #000;
  top:0;
  width:100%;
  z-index:10
}
.plyr:-webkit-full-screen{
  background:#000;
  border-radius:0!important;
  height:100%;
  margin:0;
  width:100%
}
.plyr:-moz-full-screen{
  background:#000;
  border-radius:0!important;
  height:100%;
  margin:0;
  width:100%
}
.plyr:-ms-fullscreen{
  background:#000;
  border-radius:0!important;
  height:100%;
  margin:0;
  width:100%
}
.plyr:fullscreen{
  background:#000;
  border-radius:0!important;
  height:100%;
  margin:0;
  width:100%
}
.plyr:-webkit-full-screen video{
  height:100%
}
.plyr:-moz-full-screen video{
  height:100%
}
.plyr:-ms-fullscreen video{
  height:100%
}
.plyr:fullscreen video{
  height:100%
}
.plyr:-webkit-full-screen .plyr__video-wrapper{
  height:100%;
  width:100%
}
.plyr:-moz-full-screen .plyr__video-wrapper{
  height:100%;
  width:100%
}
.plyr:-ms-fullscreen .plyr__video-wrapper{
  height:100%;
  width:100%
}
.plyr:fullscreen .plyr__video-wrapper{
  height:100%;
  width:100%
}
.plyr:-webkit-full-screen .plyr__video-embed{
  overflow:visible
}
.plyr:-moz-full-screen .plyr__video-embed{
  overflow:visible
}
.plyr:-ms-fullscreen .plyr__video-embed{
  overflow:visible
}
.plyr:fullscreen .plyr__video-embed{
  overflow:visible
}
.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper{
  height:0;
  top:50%;
  transform:translateY(-50%)
}
.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper{
  height:0;
  top:50%;
  transform:translateY(-50%)
}
.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper{
  height:0;
  top:50%;
  transform:translateY(-50%)
}
.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper{
  height:0;
  top:50%;
  transform:translateY(-50%)
}
.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen{
  display:block
}
.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen{
  display:block
}
.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen{
  display:block
}
.plyr:fullscreen .plyr__control .icon--exit-fullscreen{
  display:block
}
.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen+svg{
  display:none
}
.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen+svg{
  display:none
}
.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen+svg{
  display:none
}
.plyr:fullscreen .plyr__control .icon--exit-fullscreen+svg{
  display:none
}
.plyr:-webkit-full-screen.plyr--hide-controls{
  cursor:none
}
.plyr:-moz-full-screen.plyr--hide-controls{
  cursor:none
}
.plyr:-ms-fullscreen.plyr--hide-controls{
  cursor:none
}
.plyr:fullscreen.plyr--hide-controls{
  cursor:none
}
@media (min-width:1024px){
  .plyr:-webkit-full-screen .plyr__captions{
      font-size:21px
  }
  .plyr:-moz-full-screen .plyr__captions{
      font-size:21px
  }
  .plyr:-ms-fullscreen .plyr__captions{
      font-size:21px
  }
  .plyr:fullscreen .plyr__captions{
      font-size:21px
  }
}
.plyr:-webkit-full-screen{
  background:#000;
  border-radius:0!important;
  height:100%;
  margin:0;
  width:100%
}
.plyr:-webkit-full-screen video{
  height:100%
}
.plyr:-webkit-full-screen .plyr__video-wrapper{
  height:100%;
  width:100%
}
.plyr:-webkit-full-screen .plyr__video-embed{
  overflow:visible
}
.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper{
  height:0;
  top:50%;
  transform:translateY(-50%)
}
.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen{
  display:block
}
.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen+svg{
  display:none
}
.plyr:-webkit-full-screen.plyr--hide-controls{
  cursor:none
}
@media (min-width:1024px){
  .plyr:-webkit-full-screen .plyr__captions{
      font-size:21px
  }
}
.plyr:-moz-full-screen{
  background:#000;
  border-radius:0!important;
  height:100%;
  margin:0;
  width:100%
}
.plyr:-moz-full-screen video{
  height:100%
}
.plyr:-moz-full-screen .plyr__video-wrapper{
  height:100%;
  width:100%
}
.plyr:-moz-full-screen .plyr__video-embed{
  overflow:visible
}
.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper{
  height:0;
  top:50%;
  transform:translateY(-50%)
}
.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen{
  display:block
}
.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen+svg{
  display:none
}
.plyr:-moz-full-screen.plyr--hide-controls{
  cursor:none
}
@media (min-width:1024px){
  .plyr:-moz-full-screen .plyr__captions{
      font-size:21px
  }
}
.plyr:-ms-fullscreen{
  background:#000;
  border-radius:0!important;
  height:100%;
  margin:0;
  width:100%
}
.plyr:-ms-fullscreen video{
  height:100%
}
.plyr:-ms-fullscreen .plyr__video-wrapper{
  height:100%;
  width:100%
}
.plyr:-ms-fullscreen .plyr__video-embed{
  overflow:visible
}
.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper{
  height:0;
  top:50%;
  transform:translateY(-50%)
}
.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen{
  display:block
}
.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen+svg{
  display:none
}
.plyr:-ms-fullscreen.plyr--hide-controls{
  cursor:none
}
@media (min-width:1024px){
  .plyr:-ms-fullscreen .plyr__captions{
      font-size:21px
  }
}
.plyr--fullscreen-fallback{
  background:#000;
  border-radius:0!important;
  height:100%;
  margin:0;
  width:100%;
  bottom:0;
  left:0;
  position:fixed;
  right:0;
  top:0;
  z-index:10000000
}
.plyr--fullscreen-fallback video{
  height:100%
}
.plyr--fullscreen-fallback .plyr__video-wrapper{
  height:100%;
  width:100%
}
.plyr--fullscreen-fallback .plyr__video-embed{
  overflow:visible
}
.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper{
  height:0;
  top:50%;
  transform:translateY(-50%)
}
.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen{
  display:block
}
.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen+svg{
  display:none
}
.plyr--fullscreen-fallback.plyr--hide-controls{
  cursor:none
}
@media (min-width:1024px){
  .plyr--fullscreen-fallback .plyr__captions{
      font-size:21px
  }
}
.plyr__ads{
  border-radius:inherit;
  bottom:0;
  cursor:pointer;
  left:0;
  overflow:hidden;
  position:absolute;
  right:0;
  top:0;
  z-index:-1
}
.plyr__ads>div,.plyr__ads>div iframe{
  height:100%;
  position:absolute;
  width:100%
}
.plyr__ads::after{
  background:rgba(47,52,61,.8);
  border-radius:2px;
  bottom:10px;
  color:#fff;
  content:attr(data-badge-text);
  font-size:11px;
  padding:2px 6px;
  pointer-events:none;
  position:absolute;
  right:10px;
  z-index:3
}
.plyr__ads::after:empty{
  display:none
}
.plyr__cues{
  background:currentColor;
  display:block;
  height:6px;
  left:0;
  margin:-3px 0 0;
  opacity:.8;
  position:absolute;
  top:50%;
  width:3px;
  z-index:3
}
.plyr--no-transition{
  transition:none!important
}
.plyr__sr-only{
  clip:rect(1px,1px,1px,1px);
  overflow:hidden;
  border:0!important;
  height:1px!important;
  padding:0!important;
  position:absolute!important;
  width:1px!important
}
