@import 'player';
$break-mobile: 768px;
$break-tablet: 769px;
$break-desktop: 1024px;
$break-widescreen: 1216px;
$break-fullhd: 1408px;

$brand-primary: #FFCD1C;
$brand-secondary: #3BC5ED;

body {
  font-family: 'Gothic A1', BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

button,
.button {
  outline: none;
  border: none;
}

.browsehappy {
  background: $brand-primary;
  text-align: center;
  padding: 0.5em 0;
  margin: 0;
  color: #000;
}

.container {
  @media screen and (min-width: $break-desktop) {
    padding: 0 6rem;
  }
}

.navbar {
  transition: all 0.2s ease-in-out;
  background: none;

  .navbar-item {
    position: relative;
  }

  .navbar-burger {
    background: none;
    transition: all 0.1s ease-in-out;
  }

  .logo {
    max-height: none;
    max-width: none;
    position: absolute;
    width: 5rem;
    left: 1.5rem;
  }

  &:not(.is-white) {
    .navbar-burger {
      color: white;
    }
    .logo-black {
      display: none;
    }
    .logo-white {
      display: inline-block;
    }
  }

  &.is-white {
    box-shadow: 0 1px 24px rgba(0, 0, 0, 0.05);

    .navbar-burger {
      color: black;
    }
    .logo-black {
      display: inline-block;
    }
    .logo-white {
      display: none;
    }
  }

  .is-yellow {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.075);
  }

  .is-blue {
    color: $brand-secondary;
  }

  .is-white-bg {
    background-color: white;
  }

  .navbar-end {
    .navbar-item {
      @media screen and (max-width: $break-desktop) {
        text-align: center;
      }
    }
    .button,
    .link {
      @media screen and (max-width: $break-desktop) {
        margin: 0 auto;
        width: 100%;
      }
    }
  }
}

.footer {
  background: white;
  .fine {
    font-size: smaller;
    a {
        font: #999000;
    }
  }
}

.is-size-large {
  margin-bottom: 2.5rem !important;
  font-size: 4rem;

  @media screen and (max-width: $break-tablet) {
    font-size: 2.5rem;
  }
}

.paragraph {
  max-width: 100%;
  margin: 0 0 1.5rem 0;

  @media screen and (min-width: $break-desktop) {
    width: 80%;
  }
}

.indented {
  margin-left: 2em
}

.has-text-centered {
  .paragraph {
    margin: 0 auto 1.5rem auto;
  }
}

.has-text-yellow {
  color: $brand-primary;
}

.has-text-blue {
  color: $brand-secondary;
}

.section {
  &.is-yellow {
    background: $brand-primary;
  }
}

.hero-body,
.section {
  .button {
    height: auto;
    padding: 0.5rem 1.5rem;
  }
}

.is-yellow {
  background: $brand-primary;
}
.is-white {
  background: whitesmoke;
}

.download {
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  border-radius: 8px;
  line-height: 1;
  padding: 0.25rem 0.5rem;
  margin: 0 2rem 0 0;
  width: 8rem;

  &:hover {
    transform: scale(1.1);
  }

  &:last-of-type {
    margin: 0 auto;
  }

  img {
    margin: 0;
    width: 100%;
  }
}

.download-large {
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    border-radius: 8px;
    line-height: 2;
    padding: 0.25rem 0.5rem;
    margin: 0 2rem 0 0;
    width: 12rem;
    height: 2rem;
  
    &:hover {
      transform: scale(1.1);
    }
  
    &:last-of-type {
      margin: 0 auto;
    }
  
    img {
        margin: 0;
        border: 2px solid $brand-secondary;
        border-radius: 8px;
    }
  }

.signup-form {
  border-radius: 0.5rem;
  box-shadow: 0 4px 48px rgba(0, 0, 0, 0.075);
  background: white;
  position: relative;
  padding: 4rem;
  margin: 4rem auto 0 auto;

  @media screen and (max-width: $break-mobile) {
    padding: 2rem;
    margin: 2rem auto 0 auto;
  }

  .field {
    margin: 0 auto 2rem auto;

    &:last-of-type {
      margin: 0 auto;
    }
  }

  .label {
    margin: 0 0 1rem 0;
  }

  .checkboxList > label input[type=checkbox], label input[type=radio] {
    color: red;
    margin-right:10px;
    -ms-transform: scale(1.25); /* IE */
    -moz-transform: scale(1.25); /* FF */
    -webkit-transform: scale(1.25); /* Safari and Chrome */
    -o-transform: scale(1.25); /* Opera */
    padding: 10px;
  }
  .formSectionHeader {
    color: $brand-secondary;
    font-size: larger;
  }
}

.legal-text {
    border-radius: 0.5rem;
    box-shadow: 0 4px 48px rgba(0, 0, 0, 0.075);
    background: white;
    position: relative;
    padding: 4rem;
    margin: 4rem auto 0 auto;

    h2 {
        font-weight: bolder;
        margin-top: 2em;
        margin-bottom: 1em;
    
        li {
            list-style-type: none;
            line-height: 1.5;
        }
    }

    h3 {
        font-weight: bolder;
        margin-top: 2em;
    }
    
    h4 {
        font-weight: bolder;
        margin-top: 1em;
    }
}

#home {
  &.hero {
    background-size: cover !important;
    background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4) ), url("../images/zjEsQhDD39I.jpg") no-repeat center center fixed;
    position: relative;
    width: 100%;
    
    &::before {
      background: rgba(0, 0, 0, 0.25);
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }

    @media screen and (max-width: $break-mobile) {
      background-attachment: initial;
      background-position: center;
    }

    .hero-body {
      h1 {
        position: absolute;
        opacity: 0;
        left: 0;
        top: 0;
      }

      .hoopcam {
        height: 4rem;
      }

      .early-access {
        margin: 0 auto 2rem auto;
      }

      @media screen and (max-width: $break-mobile) {
        padding-bottom: 6rem;
        padding-top: 6rem;
      }
    }
  }
}

#app {
  padding: 3rem 0 0 0;

  @media screen and (min-width: $break-desktop) {
    padding: 6rem 0 0 0;
  }

  .container {
    padding: 0 1.5rem;

    @media screen and (min-width: $break-desktop) {
      padding: 0 7.5rem;
    }
  }

  .players {
    margin: 0 auto 2rem auto;
  }
}

.world-map-container {
  background: url("/images/qFIiJNjqB10.jpg") no-repeat center center fixed; background-size: cover;
  position: relative;
  display: inline-block;
  padding: 2rem;
  margin: 4rem auto 0 auto;
  width: 100%;

  @media screen and (max-width: $break-mobile) {
    background-attachment: initial;
    background-position: center;
  }

  &::before {
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    content: '';
    left: 0;
    top: 0;
  }

  .world-map {
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    z-index: 1;
    height: auto;
    width: 100%;

    #world {
      display: inline-block;
      width: 100%;

      #continents {
        #south-america, #north-america, #africa, #europe, #australia, #russia {
          pointer-events: bounding-box;
          circle{
            fill: rgba(255, 255, 255, 0.5);
            r: 2;
          }
          // &:hover * {
          //   fill: rgba(#35CE8D,1);
          // }
        }
        // #south-america {

        // }
        // #north-america {

        // }
        // #africa {

        // }
        // #europe {

        // }
        // #australia {

        // }
        // #russia {

        // }
      }
    }
  }
}

.how {
  margin-bottom: 2rem !important;
  margin-top: 2rem;

  .content {
    position: relative;
    display: flex;
    padding: 6rem 2rem 4rem 2rem;
    z-index: 0;

    &::before {
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }

    .text {
      position: relative;
      z-index: 1;

      strong {
        display: inline-block;
        margin: 0 auto 0.5rem auto;
        width: 100%;
      }
    }
  }
}

hr.mini {
  border-bottom: none;
  border-top: 4px solid black;
  margin: 4rem 0;
  width: 3rem;
}

.add-margin {
  margin: 2rem auto;
}

.rules,
.values,
.reserve {
  img {
    margin: 2rem auto;
    width: 4rem;
  }
}

img.prove {
  position: relative;
  display: block;
  margin: 2rem auto;
  max-width: 100%;
  width: 20rem;
}

img.support {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.09);
  position: relative;
  display: block;
  margin: 2rem auto;
  max-width: 100%;
  width: 80%;
}

.instagram-media {
  border-radius: 0 !important;
  position: relative;
  display: inline-block;
  width: 100%;
}

#highlights {
  .plyr {
    display: inline-block;
    margin: 0 auto 1rem auto;
    width: 100%;
  }
}

.values {
  margin: 0 auto 4rem auto;
}

.square-block {
    display:inline-block;
    width:120px; 
    margin-right:3px;
}